import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import ArticleByCategory from '../components/article-by-category/article-by-category';
import {
  ContentfulArticlePage,
  ContentfulEcomCommon,
  ContentfulProductCategoryPage,
  ContentfulProductHeadPage,
} from '../contentful';

interface ProductCategoryPageQueryProps {
  contentfulPageProductHead: Partial<ContentfulProductHeadPage>;
  contentfulPageProductCategory: Partial<ContentfulProductCategoryPage>;
  contentfulEcomCommon: ContentfulEcomCommon;
}

type ProductCategoryPageType = PageProps<ProductCategoryPageQueryProps>;

const ProductCategoryPage = ({ data }: ProductCategoryPageType) => {
  const productHead = data.contentfulPageProductHead;
  const articlesInCategory: ContentfulArticlePage[] = [];

  productHead.items?.forEach((product) => {
    if (product.items) {
      for (const article of product.items) {
        const articleExists = articlesInCategory.find(
          (a) => article.articleNumber === a.articleNumber,
        );
        if (!articleExists) {
          articlesInCategory.push(article);
        }
      }
    }
  });

  return (
    <>
      <Helmet
        title={`${data.contentfulPageProductCategory.title} / ${data.contentfulPageProductHead.title}`}
      />
      <ArticleByCategory
        articlesInCategory={articlesInCategory}
        ecomCommon={data.contentfulEcomCommon}
        productCatgoryTitle={data.contentfulPageProductCategory.title}
        productHeaderTitle={data.contentfulPageProductHead.title}
      />
    </>
  );
};

export const query = graphql`
  query ($productHeadId: String!, $productCategoryId: String!) {
    contentfulPageProductHead(id: { eq: $productHeadId }) {
      title
      items {
        id
        title
        __typename
        items {
          ...ArticleCard
        }
      }
    }
    contentfulPageProductCategory(id: { eq: $productCategoryId }) {
      title
    }
    contentfulEcomCommon {
      ...EcomCommon
    }
  }
`;
export default ProductCategoryPage;
