import React from 'react';
import { connect } from 'react-redux';
import { ContentfulArticlePage, ContentfulEcomCommon } from '../../contentful';
import { RootState } from '../../store';
import { ArticleCardList } from '../article-card-list';
import { FavouriteArticlePage } from '../article-card-list/favourite-article-page.type';

const mapStateToProps = (state: RootState) => {
  return { favouritesFromStore: state.shop.favourites };
};

interface ArticleByCategoryProps {
  favouritesFromStore: string[];
  articlesInCategory: ContentfulArticlePage[];
  ecomCommon: ContentfulEcomCommon;
  productCatgoryTitle?: string;
  productHeaderTitle?: string;
}

const ArticleByCategory = ({
  favouritesFromStore,
  articlesInCategory,
  ecomCommon,
  productCatgoryTitle,
  productHeaderTitle,
}: ArticleByCategoryProps) => {
  const articlesMappedToFavourites: FavouriteArticlePage[] =
    articlesInCategory.map((article) => {
      return {
        ...article,
        isFavourite: favouritesFromStore.includes(article.articleNumber),
      };
    });
  return (
    <>
      <h1>{productCatgoryTitle}</h1>
      <h2>{productHeaderTitle}</h2>
      <ArticleCardList
        articleList={articlesMappedToFavourites}
        ecomCommon={ecomCommon}
      />
    </>
  );
};

export default connect(mapStateToProps)(ArticleByCategory);
